/*
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-03-16 09:47:53
 * @LastEditors: hutian
 * @LastEditTime: 2021-03-16 10:43:57
 */
/**
* api接口统一管理
*/
import { axios } from '@/utils/request'

export const listCategoryRebatePolicy = params => axios({
    url: '/api/product/rebatePolicy/categoryRebatePolicy/listRebate',
    method: 'get',
    params,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const addCategoryRebatePolicy = params => axios({
    url: '/api/product/rebatePolicy/categoryRebatePolicy/add',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const editCategoryRebatePolicy = params => axios({
    url: '/api/category_rebate_policy/system/categoryRebatePolicy/edit',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const delCategoryRebatePolicy = params => axios({
    url:'/api/category_rebate_policy/system/categoryRebatePolicy/del/' + params,
    method: 'post',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const selectByIdCategoryRebatePolicy = params => axios({
    url: '/api/product/rebatePolicy/categoryRebatePolicy/selectById/' + params,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})
